<template>
  <div class="main-jobs">
    <div class="margin-top-30"></div>
    <jobs-list :key="key($route)" />
  </div>
</template>

<script>
import JobsList from "../../../components/jobs/JobsList";
import { mapState } from "vuex";


// Define a standalone function for fetching data
async function fetchData(store, params, query) {
  const freeText = query.freeText;
  const type = query.type;
  const mainCategory = params.mainCategory && params.mainCategory != "all" ? params.mainCategory : "";
  const isMobile = store.state.isMobile;


  let state = "";
  let city = "";

  if (query.location) {
    const locationParts = query.location.split(",");
    if (locationParts.length === 2) {
      city = locationParts[0].trim();
      state = locationParts[1].trim();
    } else if (locationParts.length === 1) {
      state = locationParts[0].trim();
    }
  }

  const qstring = {
    mainCategory: mainCategory,
    state: state,
    city: city,
    isMobile: isMobile,
    type: type,
    freeText: freeText
  };
  await store.dispatch("jobs/getJobsQueryString", {
    qstring: { qstring },
    route: { params, query }, // Construct a route-like object
  });
}

export default {
  data: function () {
    return {
      mainCategory: this.$route.params.mainCategory,
    };
  },

  computed: {
    ...mapState("jobs", {
      metaTags: (state) => state.metaTags,
    }),
  },
  methods: {
    key(route) {
      return route.fullPath;
    }
  },
  components: {
    JobsList,
  },
  watchQuery: true,
  watch: {
    // Watch for changes in $route
    '$route'(to, from) {
      if (to.path === from.path && to.query !== from.query) {
        // Call fetchData only if the query params have changed
        this.$store.commit('jobs/destroyJobsListings');

        fetchData(this.$store, this.$route.params, this.$route.query);
      }
    }
  },
  async asyncData({ app, params, store, $sentry }) {
    if (process.server) {
      // Server-side logic
      try {
        await fetchData(store, params, app.context.route.query);
      } catch (error) {
        $sentry.captureException(error)
      }
    }
  },

  async mounted() {
    if (process.client && !this.$store.state.jobs.isJobsListingsLoaded) {
      // Client-side logic
      try {
        await fetchData(this.$store, this.$route.params, this.$route.query);
      } catch (error) {
        this.$handleExceptionWithSentry(error);
      }
    }
  },

  beforeDestroy() {
    // Reset the flag when leaving the page
    this.$store.commit('jobs/destroyJobsListings');
  },

  head() {
    return {
      title: this.metaTags.title,
      link: [{ rel: "canonical", href: this.metaTags.canonical }],
      meta: this.createMetaTags(this.metaTags),
    };
  },
};
</script>
